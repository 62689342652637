import React, { useState } from 'react';
import { auth, googleAuthProvider, twitterAuthProvider } from './firebase';
import { createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { FaGoogle, FaTwitter } from 'react-icons/fa'; // Import FaTwitter instead of FaX
import './SignUp.css';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newsletter, setNewsletter] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Optionally, handle username & newsletter subscription here
      console.log('User created:', user);
    } catch (err) {
      setError(err.message);
    }
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleAuthProvider);
    } catch (err) {
      setError(err.message);
    }
  };

  const signInWithTwitter = async () => {
    try {
      await signInWithPopup(auth, twitterAuthProvider);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="sign-up-form">
      <h2>Create your account on ScoreMe.io</h2>
      <p>
        <a href="/login">Click here if you already have one</a>
      </p>
      <div className='socButtons'>
        <button className='signSocButton' onClick={signInWithGoogle}>
          <FaGoogle /> {/* Google Icon */}
        </button>
        <button className='signSocButton' onClick={signInWithTwitter}>
          <FaTwitter /> {/* Twitter Icon */}
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        {error && <p className="error">{error}</p>}
        
        <div>
          <label>Email address</label>
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
        </div>

        <div>
          <label>Username</label>
          <input 
            type="text" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            required 
          />
        </div>

        <div>
          <label>Password</label>
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
        </div>

        <div>
          <label>Confirm your password</label>
          <input 
            type="password" 
            value={confirmPassword} 
            onChange={(e) => setConfirmPassword(e.target.value)} 
            required 
          />
        </div>

        <div>
          <label>
            <input 
              type="checkbox" 
              checked={newsletter} 
              onChange={() => setNewsletter(!newsletter)} 
            />
            I want to receive the weekly newsletter 🥰
          </label>
        </div>

        <button type="submit">Create your account</button>
      </form>
    </div>
  );
};

export default SignUp;
