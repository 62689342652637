import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Menu from './Menu';
import Dashboard from './Dashboard';
import Main from './Main';
import Feed from './Feed';
import Footer from './Footer';
import Landing from './Landing';
import SignUp from './SignUp';
import Plans from './Plans';
import { ThemeProvider } from './ThemeContext';
import { PrivateRoute, PublicRoute } from './PrivateRoute';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import AdminDashboard from './AdminDashboard';
import Launch from './Launch';
import HallOfFame from './HallOfFame';
import { db } from './firebase'; // Import Firestore instance
import { doc, getDoc } from 'firebase/firestore';

function App() {
  const initialOptions = {
    "client-id": "AXh7za6HgrJeuDQ-QWTilHT2J8cIek7u3KM56maQz1pqWp2QPau660srO2zLFdfNaI141eo8z-KyKVSx", // Replace with your actual client ID
    currency: "USD",
    intent: "capture",
  };

  const [isPreLaunch, setIsPreLaunch] = useState(null); // Initially set to `null` to indicate loading

  // Fetch the Prelaunch status from Firestore
  useEffect(() => {
    const fetchPreLaunchStatus = async () => {
      try {
        const controlPanelRef = doc(db, 'controlPanel', '8SGFUGkF19XbA9LgkVfj');
        const docSnap = await getDoc(controlPanelRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setIsPreLaunch(data.Prelaunch || false); // Set state based on Firestore value
        } else {
          console.log("No such document!");
          setIsPreLaunch(false); // Default to `false` if document is missing
        }
      } catch (error) {
        console.error("Error fetching pre-launch status:", error);
        setIsPreLaunch(false);
      }
    };

    fetchPreLaunchStatus();
  }, []);

  // Show loading state until isPreLaunch is resolved
  if (isPreLaunch === null) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider>
      <Router>
        <div className="App">
          <Routes>
            {/* Redirect to /launch if pre-launch is true */}
            {isPreLaunch && (
              <>
                <Route path="/launch" element={<Launch />} />
                <Route path="*" element={<Navigate to="/launch" replace />} />
              </>
            )}
            {/* Standard routes if not in pre-launch mode */}
            {!isPreLaunch && (
              <>
                {/* Public Routes */}
                <Route element={<PublicRoute restricted={false} />}>
                  <Route path="/" element={<Landing />} />
                </Route>
                <Route element={<PublicRoute restricted={true} />}>
                  <Route path="/signup" element={<SignUp />} />
                </Route>

                {/* Private Routes */}
                <Route element={<PrivateRoute />}>
                  <Route path="/home" element={<AppWithHeaderAndFooter><Main /></AppWithHeaderAndFooter>} />
                  <Route path="/dashboard" element={<AppWithHeaderAndFooter><Dashboard /></AppWithHeaderAndFooter>} />
                  <Route path="/feed" element={<AppWithHeaderAndFooter><Feed /></AppWithHeaderAndFooter>} />
                  <Route path="/products" element={<AppWithHeaderAndFooter><HallOfFame /></AppWithHeaderAndFooter>} />
                  <Route path="/admin" element={<AppWithHeaderAndFooter><AdminDashboard /></AppWithHeaderAndFooter>} />
                  <Route path="/plans" element={<AppWithHeaderAndFooter><PayPalScriptProvider options={initialOptions}><Plans /></PayPalScriptProvider></AppWithHeaderAndFooter>} />
                </Route>
                <Route path="/launch" element={<Navigate to="/" replace />} /> {/* Redirect to home if pre-launch is false */}
              </>
            )}
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

// Component to wrap routes that need Header and Footer
const AppWithHeaderAndFooter = ({ children }) => (
  <div className="App">
    <header className="App-header">
      <Menu />
    </header>
    <main className="App-main">
      {children}
    </main>
    <footer>
      <Footer />
    </footer>
  </div>
);

export default App;
