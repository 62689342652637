import { PayPalButtons } from "@paypal/react-paypal-js";

const PayPalButton = ({ price, currency, onSuccess, onError }) => {
  return (
    <div>
      <PayPalButtons
        style={{ layout: "vertical" }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: price,
                  currency_code: currency,
                },
              },
            ],
          });
        }}
        onApprove={async (data, actions) => {
          const order = await actions.order.capture();
          onSuccess(order);
        }}
        onError={(err) => {
          console.error("PayPal checkout error", err);
          onError(err);
        }}
      />
    </div>
  );
};

export default PayPalButton;
