import React, { useEffect, useState } from 'react';
import './CircularScore.css'; // Import a CSS file for styling

const CircularScore = ({ score }) => {
    const [animatedScore, setAnimatedScore] = useState(0); // State for animated score
    const radius = 40; // Radius of the circle
    const circumference = 2 * Math.PI * radius; // Circumference of the circle

    useEffect(() => {
        // Animate from 0 to the score value
        const start = 0;
        const end = score;
        const duration = 1500; // Animation duration in milliseconds
        const incrementTime = duration / end; // Calculate time for each increment

        let currentScore = start;

        const incrementScore = () => {
            if (currentScore < end) {
                currentScore++;
                setAnimatedScore(currentScore);
                setTimeout(incrementScore, incrementTime); // Update the score every incrementTime milliseconds
            }
        };

        incrementScore(); // Start the animation
    }, [score]);

    const offset = circumference - (animatedScore / 100) * circumference; // Offset for the filled portion

    return (
        <svg width="100" height="100" className="circular-progress">
            <circle
                className="background-circle"
                cx="50"
                cy="50"
                r={radius}
                fill="transparent"
                stroke="white"
                strokeWidth="8" // Stroke width
            />
            <circle
                className="progress-circle"
                cx="50"
                cy="50"
                r={radius}
                fill="transparent"
                stroke="orange"
                strokeWidth="8" // Stroke width
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                style={{ transition: 'stroke-dashoffset 0.1s ease-in-out' }} // Adjusted transition duration
            />
            <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" className="score-text">
                {animatedScore} {/* Display animated score */}
            </text>
        </svg>
    );
};

export default CircularScore;
