import React, { useState } from 'react';
import axios from 'axios';
import './App.css';
import './Rate.css';

function Product() {
    const [url, setUrl] = useState('');
    const [query, setQuery] = useState('');
    const [image, setImage] = useState('');
    const [error, setError] = useState(null);
    const [visionResponse, setVisionResponse] = useState('');
    const [selectedCriteria, setSelectedCriteria] = useState([]);

    // List of all criteria
    const criteriaList = [
        "Design Foundations",
        "Visual Hierarchy",
        "Layout and Spacing",
        "Typography",
        "Color",
        "Theory",
        "Depth",
        "Design Assets",
        "Details"
    ];

    // Toggle checkbox selection
    const toggleCriteria = (criterion) => {
        setSelectedCriteria(prevSelected => {
            if (prevSelected.includes(criterion)) {
                return prevSelected.filter(item => item !== criterion);
            } else {
                return [...prevSelected, criterion];
            }
        });
    };

    // Function to handle image file upload and convert it to base64
const handleImageUpload = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        const base64 = reader.result.split(',')[1]; // Remove data:*/*;base64, part
        setImage(reader.result); // Display image
        setUrl(base64); // Save base64 string for API request
    };
    reader.onerror = (error) => {
        console.error("Error converting image to Base64:", error);
        setError(error);
    };
};

// Construct the text for the API request
const constructRequestText = () => {
    const criteriaText = selectedCriteria.join(", ");
    return `Provide me some insights based on this visual: each criteria should be estimated from 0 to 100 points: Overall Feedback, ${criteriaText}`;
};

const requestText = constructRequestText();

// Function to analyze the image using OpenAI API
const analyzeImage = async () => {
    if (!url) {
        setError("No image uploaded");
        return;
    }

    const payload = {
        model: "gpt-4-vision-preview",
        messages: [
            {
                "role": "user",
                "content": [
                    {
                        "type": "text",
                        "text": requestText
                    },
                    {
                        "type": "image_url",
                        "image_url": {
                            "url": `data:image/jpeg;base64,${url}` // The Base64 image string
                        }
                    }
                ]
            }
        ],
        max_tokens: 2000
    };

    try {
        const response = await axios.post("https://api.openai.com/v1/chat/completions", payload, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer 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`
            }
        });

        setVisionResponse(response.data.choices[0].message.content);
        // You might need to adjust this line depending on the structure of the response
    } catch (error) {
        console.error("Error making analysis request:", error);
        setError(error);
    }
};

    const splitResponseIntoSections = () => {
        const sections = {};
        const responseLines = visionResponse.split('\n');
        let currentCriterion = "";
        responseLines.forEach(line => {
            // Match the start of a new criterion, which has a numeric prefix
            const criterionMatch = line.match(/^\d+\.\s*(.*?):\s*(\d+)/);
            if (criterionMatch) {
                const criterion = criterionMatch[1];
                const score = criterionMatch[2];
                currentCriterion = `${criterion}: ${score} / 100`;
                sections[currentCriterion] = '';
            } else if (currentCriterion && line.trim().startsWith('-')) {
                // Assumes the description follows and is prefixed with a dash
                if (sections[currentCriterion]) {
                    sections[currentCriterion] += "\n" + line.trim().substring(1).trim(); // Add a newline before appending
                } else {
                    sections[currentCriterion] = line.trim().substring(1).trim();
                }
            }
        });
        return sections;
    };

    // Render function or component...
    const responseSections = splitResponseIntoSections();

    return (
        <div className="App">
            <header className="App-header">
            </header>
            <body className='rateBody'>
                <div className='rateUpper'>
                    <div>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageUpload(e.target.files[0])} // Handle file upload
                        />
                        <button className="enterButton" onClick={analyzeImage}>Rate me</button>

                    </div>
                    <div className='criteriaList'>
                        {criteriaList.map((criterion, index) => (
                            <div
                                key={index}
                                className={`criteriaItem ${selectedCriteria.includes(criterion) ? 'selected' : ''}`}
                                onClick={() => toggleCriteria(criterion)}
                            >
                                {criterion}
                            </div>
                        ))}
                    </div>

                </div>
                <div className='rateResponse'>
                    <div className='rateResponseImage'>
                        {image && <img src={image} alt="Screenshot" className='productImage' />}
                    </div>

                    <div className='rateResponseText'>
                        {Object.entries(responseSections).map(([key, text], index) => {
                            return (
                                <div key={index} className='responseBox'>
                                    <h3 dangerouslySetInnerHTML={{ __html: key.replace(/: (\d+ \/ \d+)/, ': <span class="scoreStyle">$1</span>') }}></h3>
                                    <p style={{ whiteSpace: 'pre-wrap' }}>{text}</p>
                                </div>
                            );
                        })}
                    </div>


                </div>
            </body>
        </div>
    );
}

export default Product;