import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import './Rate.css';
import { auth, db } from './firebase.js';
import { collection, doc, setDoc, serverTimestamp, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Lottie from 'react-lottie';
import animationData from './images/lottieAnim.json'; // Adjust the path as necessary
import Feed from './Feed.js';

function Rate() {
    const [url, setUrl] = useState('');
    const [query, setQuery] = useState('');
    const [image, setImage] = useState('');
    const [error, setError] = useState(null);
    const [visionResponse, setVisionResponse] = useState('');
    const [selectedCriteria, setSelectedCriteria] = useState([]);
    const [showCriteriaError, setShowCriteriaError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [screenshotUrl, setScreenshotUrl] = useState('');
    const [responseSections, setResponseSections] = useState({});
    const [pageOption, setPageOption] = useState('None'); // New state for page option
    const [showPageError, setShowPageError] = useState(false); // New state for page option error
    const storage = getStorage();
    const [currentResponseId, setCurrentResponseId] = useState(null);
    const [postSaved, setPostSaved] = useState(false);

    useEffect(() => {
        if (visionResponse) {
            const sections = splitResponseIntoSections(visionResponse);
            console.log("Processed Sections:", sections);
            setResponseSections(sections);
        }
    }, [visionResponse]); // Ensure visionResponse is a dependency

    const splitResponseIntoSections = (visionResponseText) => {
        const sections = {};
        const responseLines = visionResponseText.split('\n');
        let currentSectionTitle = null;
        let currentText = [];
        let currentScore = null;

        responseLines.forEach(line => {
            const scoreMatch = line.match(/Score: (\d+(?:\/\d+)?)/); // Matches '80', '80/100'
            if (scoreMatch) {
                currentScore = scoreMatch[1]; // Capture just the numeric score
                // Skip adding this line to the currentText
                return; // This prevents the score line from being added to the text body
            }
            const foundTitle = criteriaList.find(title => line.startsWith(title)) || (line.startsWith("Overall Feedback") ? "Overall Feedback" : null);
            if (foundTitle) {
                if (currentSectionTitle !== null) {
                    sections[currentSectionTitle] = {
                        text: currentText.join('\n'),
                        score: currentScore
                    };
                }
                currentSectionTitle = foundTitle;
                currentText = [];
                currentScore = null; // Reset score for the next section
            } else {
                currentText.push(line);
            }
        });

        if (currentSectionTitle !== null) {
            sections[currentSectionTitle] = {
                text: currentText.join('\n'),
                score: currentScore
            };
        }

        return sections;
    };

    // List of all criteria
    const criteriaList = [
        "Design Foundations",
        "Visual Hierarchy",
        "Layout and Spacing",
        "Color",
        "Typography",
        "Theory",
        "Depth",
        "Design Assets",
        "Details"
    ];

    const toggleCriteria = (criterion) => {
        setSelectedCriteria(prevSelected => {
            const newSelection = prevSelected.includes(criterion) ? prevSelected.filter(item => item !== criterion) : [...prevSelected, criterion];
            setShowCriteriaError(newSelection.length === 0); // Show error if no criteria selected.
            return newSelection;
        });
    };

    const fetchImageAndAnalyze = () => {
        // First, reset both error states to ensure we start with a clean slate each time the function is called.
        setShowPageError(false);
        setShowCriteriaError(false);

        // First priority: Check if 'None' is selected for pageOption
        if (pageOption === 'None') {
            setShowPageError(true);
            return; // Stop further execution if no page option selected
        }

        // Second priority: Check if no criteria are selected
        if (selectedCriteria.length === 0) {
            setShowCriteriaError(true);
            return; // Stop further execution if no criteria selected
        }

        setIsLoading(true);
        const encodedUrl = encodeURIComponent(url);
        const fullPage = pageOption === 'Full Page' ? 'true' : pageOption === 'First Screen' ? 'false' : null;
        let viewportWidth = 1920;
        let viewportHeight = 1280;
        let fullPageOption = `&full_page=${fullPage}`;

        if (pageOption === 'Mobile') {
            viewportWidth = 430;
            viewportHeight = 932;
            fullPageOption = `&full_page=true`;
        }

        const apiUrl = `https://api.screenshotone.com/take?access_key=4IgCQfpvvOV8Cw&url=${encodeURIComponent(url)}&viewport_width=${viewportWidth}&viewport_height=${viewportHeight}&device_scale_factor=1&format=jpg&image_quality=80&block_ads=true&block_cookie_banners=true&block_banners_by_heuristics=false&block_trackers=true&delay=0&timeout=60${fullPageOption}`;
        axios.get(apiUrl, { responseType: 'blob' })
            .then(async response => {
                const screenshotBlob = response.data;
                const reader = new FileReader();
                reader.readAsDataURL(screenshotBlob);
                reader.onloadend = async () => {
                    const base64Data = reader.result;
                    console.log('Blob converted to Base64 successfully', base64Data.slice(0, 100)); // Logs the first 100 chars of the base64 string

                    const storageRef = ref(storage, `screenshots/${Date.now()}_screenshot.jpg`);
                    const snapshot = await uploadBytes(storageRef, screenshotBlob);
                    const downloadURL = await getDownloadURL(snapshot.ref);

                    setScreenshotUrl(downloadURL);
                    setImage(downloadURL);
                    const requestText = constructRequestText();
                    analyzeImage(base64Data, requestText, downloadURL);  // Ensure correct base64 string is passed
                };
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching and processing screenshot:', error);
                setError(error.message);
                setIsLoading(false);
            });
    };

    const analyzeImage = (base64Data, requestText, downloadURL) => {
        setIsLoading(true);
        const headers = {
            "Content-Type": "application/json",
            "Authorization": `Bearer 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` // Make sure this is securely handled
        };

        const payload = {
            "model": "gpt-4-vision-preview",
            "messages": [{
                "role": "user",
                "content": [
                    {
                        "type": "text",
                        "text": requestText
                    },
                    {
                        "type": "image_url",
                        "image_url": downloadURL  // Use the URL from Firebase Storage
                    }
                ]
            }],
            "max_tokens": 2000
        };

        axios.post("https://api.openai.com/v1/chat/completions", payload, { headers: headers })
            .then(response => {
                const textResponse = response.data.choices[0].message.content;
                console.log('Response from OpenAI:', textResponse);
                setVisionResponse(textResponse);  // Ensure this line is executed properly
                saveResponseToFirestore(textResponse, downloadURL);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error making API request:', error);
                setError(error.message);
                setIsLoading(false);
            });
    };

    // Construct the text for the API request
    const constructRequestText = () => {
        const criteriaText = selectedCriteria.join(", ");
        return `Assess the provided visual as a Product Designer and give advice based on the visual quality, even if the image is cropped. Provide a structured response with each criterion on a new line. Each criterion should include a title without any symbols, an estimation score from 0 to 100, and specific advice. Score provide in format one value. Required criteria: Overall Feedback, ${criteriaText}.`;
    };

    const saveResponseToFirestore = async (textResponse, screenshotUrl) => {
        const user = auth.currentUser;
        if (user) {
            const overallScore = extractOverallScore(textResponse);
            const responsesRef = collection(db, `history/${user.uid}/responses`);
            try {
                const docRef = await addDoc(responsesRef, {
                    textResponse,
                    imageUrl: screenshotUrl,
                    dateResponse: serverTimestamp(),
                    postResponse: false,
                    saveResponse: false,
                    likeResponse: 0,
                    siteUrl: url,
                    email: user.email,
                    overallScore: overallScore !== null ? parseInt(overallScore, 10) : null // Convert score to number if it exists
                });
                console.log("Document written with ID: ", docRef.id);
                setCurrentResponseId(docRef.id);  // Store the response ID
            } catch (error) {
                console.error("Error adding document: ", error);
            }
        } else {
            console.error("No user logged in!");
        }
    };

    const extractOverallScore = (textResponse) => {
        const overallFeedbackMatch = textResponse.match(/Overall Feedback\s*Score: (\d+)/);
        return overallFeedbackMatch ? overallFeedbackMatch[1] : null;
    };

    const savePost = async () => {
        if (currentResponseId) {
            const responseDocRef = doc(db, `history/${auth.currentUser.uid}/responses`, currentResponseId);
            try {
                await setDoc(responseDocRef, { saveResponse: true }, { merge: true });
                console.log("Post saved successfully!");
                setPostSaved(true);  // Update the state to indicate the post has been saved
            } catch (error) {
                console.error("Error saving post: ", error);
            }
        } else {
            console.error("No response ID available to update!");
        }
    };

    return (
        <div className='rateBody'>
            <div className='rateUpper'>
    <div className="selectorProduct">
        <div className="pageOption">
            <label className={`option ${pageOption === 'Full Page' ? 'active' : ''}`}>
                <input
                    type="radio"
                    value="Full Page"
                    checked={pageOption === 'Full Page'}
                    onChange={e => {
                        setPageOption(e.target.value);
                        setShowPageError(false);
                    }}
                />
                Full Page
            </label>
            <label className={`option ${pageOption === 'First Screen' ? 'active' : ''}`}>
                <input
                    type="radio"
                    value="First Screen"
                    checked={pageOption === 'First Screen'}
                    onChange={e => {
                        setPageOption(e.target.value);
                        setShowPageError(false);
                    }}
                />
                First Screen
            </label>
            <label className={`option ${pageOption === 'Mobile' ? 'active' : ''}`}>
                <input
                    type="radio"
                    value="Mobile"
                    checked={pageOption === 'Mobile'}
                    onChange={e => {
                        setPageOption(e.target.value);
                        setShowPageError(false);
                    }}
                />
                Mobile
            </label>
        </div>
    </div>
    <div className='inputRateField'>
        <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="https://example.com"
        />
        <div className="enterButton" onClick={() => {
            const requestText = constructRequestText();
            setQuery(requestText); // Set the query to the constructed text
            fetchImageAndAnalyze();
        }}>Rate me</div>
    </div>
    {showPageError && <div className="error">Choose page option!</div>}
    {showCriteriaError && <div className="error">You should choose at least one option!</div>}
    <div className='criteriaList'>
        {criteriaList.map((criterion, index) => (
            <div
                key={index}
                className={`criteriaItem ${selectedCriteria.includes(criterion) ? 'selected' : ''}`}
                onClick={() => toggleCriteria(criterion)}
            >
                {criterion}
            </div>
        ))}
    </div>
</div>
            <div className='rateResponse'>
                {isLoading ? (
                    <div className="loader">
                        <Lottie options={{ loop: true, autoplay: true, animationData: animationData, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} height={400} width={400} />
                    </div>
                ) : (
                    <>
                        <div className='rateResponseImage'>
                            {image && <img src={image} alt="Screenshot" className='productImage' />}
                        </div>
                        <div className='rateResponseText'>
                            {currentResponseId && (
                                <div className='saveThisPost'>
                                    Save this post?
                                    <span onClick={!postSaved ? savePost : null} className='saveThisButton'>
                                        {postSaved ? "Post Saved" : "Yes"}
                                    </span>
                                </div>
                            )}
                            {Object.entries(responseSections).map(([key, { text, score }], index) => (
                                <div key={index} className='responseBox'>
                                    <h3>{key}{score && <span className="scoreStyle"> {score}</span>}</h3>
                                    <p style={{ whiteSpace: 'pre-wrap' }}>{text}</p>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Rate;
