import React, { useState } from 'react';
import './Plans.css';
import { FaCheckCircle, FaCoins, FaHeart, FaLightbulb, FaStar } from 'react-icons/fa';
import PayPalButton from './PayPalButton'; // Import the PayPalButton
import { doc, collection, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './firebase'; // Ensure that you have Firebase properly initialized in your project
import { getAuth } from 'firebase/auth';


const Plans = () => {
  const [selectedPlan, setSelectedPlan] = useState('Monthly');

  const handlePlanChange = (plan) => {
    setSelectedPlan(plan);
  };

  const handleSuccess = async (order) => {
    console.log('Order Successful:', order);
  
    // Get the current user's UID
    const auth = getAuth();
    const user = auth.currentUser; // Get the currently signed-in user
    const userId = user ? user.uid : null; // Extract UID, if user is logged in
  
    // Save order details to Firestore
    const orderDocRef = doc(collection(db, 'orders'), order.id);
    await setDoc(orderDocRef, {
      orderID: order.id,
      amount: order.purchase_units[0].amount.value,
      currency: order.purchase_units[0].amount.currency_code,
      timestamp: serverTimestamp(),
      userId: userId, // Store user ID here
    });
  
    // Redirect or show a success message
  };
  

  const handleError = (error) => {
    console.error('PayPal Checkout Error:', error);
  };

  return (
    <div className="pricing-page">
      <h1>Premium Access</h1>
        <div className='desciptionAccessArea'>
        <p className='desciptionAccess'>Get more from premium access of Score Me, with subscription plans or purchase credits. Choose from your individual needed, best options for score your product or fast share in public.</p>
        </div>
      <div className="plan-selector">
  <div className="bubble-container">
    <button className={selectedPlan === 'Monthly' ? 'active' : ''} onClick={() => handlePlanChange('Monthly')}>
      Monthly
    </button>
    <button className={selectedPlan === 'Yearly' ? 'active' : ''} onClick={() => handlePlanChange('Yearly')}>
      Annual
    </button>
    <button className={selectedPlan === 'Credits' ? 'active' : ''} onClick={() => handlePlanChange('Credits')}>
      Credits
    </button>
    <div className={`active-bubble ${selectedPlan}`} />
  </div>
</div>


      <div className="pricing-cards">
        {selectedPlan === 'Monthly' && (
          <>
            <PriceCard
              icon={<FaLightbulb/>}
              title="Newbie"
              description="Free plan. Ideal for score your first product."
              price="Free"
              details={['1 credit per day', 'Free support', 'Verificate post up to 2 days']}
            />
            <PriceCard
              icon={<FaHeart/>}
              label="Most popular"
              title="Basic"
              description="Free plan. Ideal for score your first product."
              price="7.99"
              period="/month"
              details={['5 credits per day', 'Support 24/7', 'Verificate post for 24 hours']}
              onSuccess={handleSuccess}
              onError={handleError}
            />
            <PriceCard
              icon={<FaStar/>}
              label="Best price"
              title="Premium"
              description="Free plan. Ideal for score your first product."
              price="14.99"
              period="/month"
              details={['10 credits per day', 'Support 24/7', 'Verificate post for 1 hour']}
              onSuccess={handleSuccess}
              onError={handleError}
            />
          </>
        )}

        {selectedPlan === 'Yearly' && (
          <>
            <PriceCard
              icon={<FaLightbulb/>}
              title="Newbie"
              description="Free plan. Ideal for score your first product."
              price="Free"
              details={['1 credit per day', 'Free support', 'Verificate post up to 2 days']}
            />
            <PriceCard
              icon={<FaHeart/>}
              label="Most popular"
              title="Basic"
              description="Free plan. Ideal for score your first product."
              price="67"
              period="/year (Save 30%)"
              details={['5 credits per day', 'Support 24/7', 'Verificate post for 24 hours']}
              onSuccess={handleSuccess}
              onError={handleError}
            />
            <PriceCard
              icon={<FaStar/>}
              label="Best price"
              title="Premium"
              description="Free plan. Ideal for score your first product."
              price="104"
              period="/year (Save 40%)"
              details={['10 credits per day', 'Support 24/7', 'Verificate post for 1 hour']}
              onSuccess={handleSuccess}
              onError={handleError}
            />
          </>
        )}

        {selectedPlan === 'Credits' && (
          <>
            <PriceCard
              icon={<FaCoins/>}
              title="1 Credit"
              price="1.2"
              details={['1 credit']}
              onSuccess={handleSuccess}
              onError={handleError}
            />
            <PriceCard
              icon={<FaCoins/>}
              title="5 Credits"
              price="5.4"
              period="(save 10%)"
              details={['5 credits']}
              onSuccess={handleSuccess}
              onError={handleError}
            />
            <PriceCard
              icon={<FaCoins/>}
              title="10 Credits"
              price="10"
              period="(save 20%)"
              details={['10 credits']}
              onSuccess={handleSuccess}
              onError={handleError}
            />
          </>
        )}
      </div>
    </div>
  );
};

const PriceCard = ({icon, label, title, description, price, period, details, onSuccess, onError }) => {
  return (
    <div className="price-card">
      <div className='priceCardTop'>
        <div >
            <div className='priceCardIcon'>{icon}</div>
        </div>
        {label && (
          <div>
            <div className='priceCardLabel'>{label}</div>
          </div>
        )}
      </div>
      <h2>{title}</h2>
      <p>{description}</p>
      <div className='pricePeriod'>
      <h3>{price === "Free" ? "Free" : `$${price}`}</h3>
      <span>{period}</span>
      </div>
      <div className='divider'></div>
      <ul>
        {details.map((detail, index) => (
           <li key={index}><FaCheckCircle/>{detail}</li>
        ))}
      </ul>
      <div className='divider'></div>

{/* PayPal Button */}
{price !== "Free" && (
  <PayPalButton
    price={price}
    currency="USD" // Change this if needed
    onSuccess={onSuccess}
    onError={onError}
  />
)}
    </div>
  );
};

export default Plans;
