import React, { useEffect, useRef } from 'react';
import './Landing.css';
import Logo from './images/Logo.png';
import Arrow from './images/Arrow.png';
import Sample from './images/imageSample.avif';
import Lottie from 'react-lottie';
import animationData from './images/mainAnim2.json';
import ScreenHome from './images/ScreenHome.jpg';
import ParallaxSpace from './ParallaxSpace'; // Import the ParallaxSpace component
import './ParallaxSpace.css'; // Create a separate CSS file for styles

function Landing() {
    const elementsRef = useRef([]);

    useEffect(() => {
        // Apply the animation to each element
        elementsRef.current.forEach((el, index) => {
            if (el) {
                el.classList.add('animated');
            }
        });
    }, []);

    const canvasRef = useRef(null);
    const stars = [];

    const createStars = (numStars) => {
        for (let i = 0; i < numStars; i++) {
            stars.push({
                x: Math.random() * window.innerWidth,
                y: Math.random() * window.innerHeight,
                radius: Math.random() * 1.5 + 0.5,
                speed: Math.random() * 0.5 + 0.2,
            });
        }
    };

    const drawStars = (ctx) => {
        ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
        stars.forEach((star) => {
            ctx.beginPath();
            ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
            ctx.fillStyle = 'white'; // Star color
            ctx.fill();
            // Move stars downward with speed
            star.y += star.speed;
            if (star.y > window.innerHeight) {
                star.y = 0;
                star.x = Math.random() * window.innerWidth;
            }
        });
    };

    const animate = () => {
        const ctx = canvasRef.current.getContext('2d');
        drawStars(ctx);
        requestAnimationFrame(animate);
    };

    useEffect(() => {
        createStars(100); // Adjust the number of stars
        animate();
        return () => {
            cancelAnimationFrame(animate);
        };
    }, []);
    return (
        <div className='landMain'>
            <div className='landHeader animate-fade-drop animate-delay-1' ref={(el) => elementsRef.current[0] = el}>
                <div className='logoArea'>
                    <img src={Logo} alt='logo' className='logoImg' /><span className='logoText'>scoreme.io</span>
                </div>
                <div className='landButtons'>
                    <a href='' className='menuButton'>About</a>
                    <a href='' className='menuButton'>How it works</a>
                    <a href='/signup' className='buttonSignUp'>Get started free</a>
                    <a href='/home' className='buttonSignIn'>Sign in</a>
                </div>
            </div>
            <div className="parallax-background">
                <canvas
                    ref={canvasRef}
                    width={window.innerWidth}
                    height={window.innerHeight}

                />
                <div className='boardBoxB '>
                    <div className='boardBoxC'>
                        <div className='boardBox animate-fade-drop animate-delay-2' ref={(el) => elementsRef.current[1] = el}>
                            <div className='leftBoardBox'>
                                <h1 className='productTitle'>We will score your <span className='productText'>Products</span></h1>
                                <div className='descProductText'>
                                    Join us, score your product and share it <img src={Arrow} alt='arrow' className='arrow' />
                                </div>
                                <div className='boxCenterButton'>
                                    <div className='waitlistButton'><a href='/signup'>Start now</a></div>
                                </div>


                            </div>
                            <div className='rightBoardBox'>
    <Lottie
        options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
        }}
    />
</div>


                        </div>
                    </div>
                </div>
            </div>
            <div className='boardBoxA'>
                <div className='boardBoxMid animate-fade-drop animate-delay-3' ref={(el) => elementsRef.current[2] = el}>
                    <h2>Things you can do with us:</h2>
                    <p>We ensure that you'll embark on a perfectly design, take advice to make it better</p>
                    <div className='cardArea'>
                        <div className='cardBox2'>
                            <img src={Sample} className='sampleImage' />
                            <div className='titleCard'>
                                Score product
                            </div>
                            <div className='descCard'>
                                Take your score and advice with criteria on your choose!
                            </div>
                        </div>
                        <div className='cardBox1'>
                            <img src={Sample} className='sampleImage' />
                            <div className='titleCard'>
                                Work in dashboard
                            </div>
                            <div className='descCard'>
                                Take your score and advice with criteria on your choose!
                            </div>
                        </div>

                    </div>
                    <div className='cardArea'>
                        <div className='cardBox1'>
                            <img src={Sample} className='sampleImage' />
                            <div className='titleCard'>
                                List your products
                            </div>
                            <div className='descCard'>
                                Take your score and advice with criteria on your choose!
                            </div>
                        </div>
                        <div className='cardBox2'>
                            <div className='titleCardBig'>
                                View All Tools
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='boardBoxA'>
                <div className='boardBoxMid animate-fade-drop animate-delay-3' ref={(el) => elementsRef.current[3] = el}>
                    <h2>Your AI Report - roast your idea</h2>
                    <p>Powerful analysis, no fluff. Elevate from 'meh' to masterwork—fast.</p>
                    <div className='aiReport'>
                        <div className='aiReportLeft'>
                            <img src={ScreenHome} alt='home' />
                        </div>
                        <div className='aiReportRight'>
                            <div class="responseBox">
                                <h3>Overall Feedback<span class="scoreStyle"> 75</span></h3>
                                <p>
                                    The design appears clean and minimal, which is good for user focus and experience. However, it could benefit from a touch of personality or a more distinctive style to make it stand out.
                                </p>
                            </div>
                            <div class="responseBox">
                                <h3>Design Foundations<span class="scoreStyle"> 85</span></h3>
                                <p>
                                    Foundational design principles such as alignment, contrast, and balance have been well maintained. There is a clear hierarchy, but additional work on micro-interactions and unique elements could improve the engagement.
                                </p>
                            </div>
                            <div class="responseBox">
                                <h3>Layout and Spacing<span class="scoreStyle"> 80</span></h3>
                                <p>
                                    Layout and spacing are executed nicely, contributing to a straightforward user journey. However, the spacing between the tags at the bottom (like "Design Foundations," "Typography," etc.) could be adjusted for better visual grouping and to aid scanability.
                                </p>
                            </div>
                            <div class="responseBox">
                                <h3>Color<span class="scoreStyle"> 70</span></h3>
                                <p>
                                    The monochrome palette is aesthetically pleasing, but it lacks a range of color that could help guide users' attention to important elements such as the 'Rate me' button.
                                </p>
                            </div>
                            <div class="responseBox">
                                <h3>Depth<span class="scoreStyle"> 60</span></h3>
                                <p>
                                    The design is quite flat, which is modern, but adding subtle shadows or highlights could help in creating a sense of depth that can improve user interface dynamics.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='boardBoxA'>
                <div className='boardBoxMid animate-fade-drop animate-delay-3' ref={(el) => elementsRef.current[4] = el}>
                    <h2>The Numbers</h2>
                    <div className='percentanceBoard'>
                        <div className='percentCard'>
                            <div className='percentText'>
                                <h2>1.20 min</h2>
                                <p>Avarage time for scoring product</p>
                            </div>
                            <div className='percentText'>
                                <h2>93%</h2>
                                <p>Accuracy of scoring design</p>
                            </div>
                        </div>
                        <div className='percentCard'>
                            <div className='percentText'>
                                <h2>85%</h2>
                                <p>User satisfaction of estimation results</p>
                            </div>
                            <div className='percentText'>
                                <h2>100%</h2>
                                <p>Free-to-use with all tools and sharing product</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footerLand'>
                <div className='footerRows'>
                    <div className='footerColumns'>
                    <div className='footerRow'>
                        <div className='logoFooter'>
                        <img src={Logo} alt='logo' className='logoImg' /><span className='logoText'>scoreme.io</span>
                        </div>
                        <div>
                        ScoreMe.io is the ultimate platform for product evaluation and discovery. Our tool allows you to score and rate digital and physical products based on objective criteria, powered by AI insights. Whether you're a creator, entrepreneur, or consumer, you can easily share your product feedback, connect with our community, and gain visibility for your innovations. Post your products publicly, get likes, and rise in popularity with our Hall of Fame feature. Join ScoreMe.io today and make your product stand out in a competitive market!
                        </div>
                    </div>
                    <div className='footerRow'>
                        <div>
                            <b>Score Me</b>
                        </div>
                        <div>
                            <a href=''>How it works</a>
                        </div>
                        <div>
                            <a href=''>FAQs</a>
                        </div>
                        <div>
                            <a href=''>Score digital products</a>
                        </div>
                        <div>
                            <a href=''>Score physic products</a>
                        </div>
                        <div>
                            <a href=''>Feed</a>
                        </div>
                    </div>
                    <div className='footerRow'>
                        <div>
                            <b>Social Networks</b>
                        </div>
                        <div>
                            <a href=''>Twitter</a>
                        </div>
                        <div>
                            <a href=''>Instagram</a>
                        </div>
                        <div>
                            <a href=''>Facebook</a>
                        </div>
                        <div>
                            <a href=''>Email</a>
                        </div>
                    </div>
                    </div>
                </div>
                <div>
                    © 2024 ScoreMe.io™. All Rights Reserved. Privacy Policy
                </div>
            </div>
        </div>
    );
}

export default Landing;
