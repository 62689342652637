import React, { useEffect, useState, useCallback } from 'react';
import { db, auth } from './firebase'; // Make sure firebase is correctly initialized in your project
import { collection, query, getDocs, where, doc, getDoc, updateDoc } from 'firebase/firestore';
import './AdminDashboard.css';

function AdminDashboard() {
    const [posts, setPosts] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);
    const [topWidget, setTopWidget] = useState(false); // State for the topWidget toggle

    const checkAdminStatus = useCallback(async () => {
        const user = auth.currentUser;
        if (user) {
            try {
                const userDocRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    if (userData.admin) {
                        setIsAdmin(true);
                    }
                } else {
                    console.error("No such document!");
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        }
        setLoading(false);
    }, []);

    const fetchTopWidgetSetting = useCallback(async () => {
        try {
            const controlPanelRef = doc(db, 'controlPanel', '8SGFUGkF19XbA9LgkVfj'); // Replace 'document_id' with your actual document ID
            const docSnap = await getDoc(controlPanelRef);
            if (docSnap.exists()) {
                setTopWidget(docSnap.data().topWidget || false);
            } else {
                console.log("No such document in controlPanel!");
            }
        } catch (error) {
            console.error("Error fetching topWidget setting:", error);
        }
    }, []);

    const handleToggleTopWidget = async () => {
        try {
            const controlPanelRef = doc(db, 'controlPanel', '8SGFUGkF19XbA9LgkVfj'); // Replace 'document_id' with your actual document ID
            await updateDoc(controlPanelRef, { topWidget: !topWidget });
            setTopWidget(!topWidget); // Toggle the state after successful update
        } catch (error) {
            console.error("Error updating topWidget setting:", error);
        }
    };

    const fetchPostsForReview = useCallback(async () => {
        setLoading(true);
        const user = auth.currentUser;
        if (user) {
            try {
                const q = query(
                    collection(db, `history/${user.uid}/responses`),
                    where("postReview", "==", true)
                );
                const querySnapshot = await getDocs(q);
                const fetchedPosts = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setPosts(fetchedPosts);
            } catch (error) {
                console.error("Error fetching posts:", error);
            }
        }
        setLoading(false);
    }, []);

    const togglePostStatus = async (postId, currentStatus) => {
        const user = auth.currentUser;
        if (user) {
            const postRef = doc(db, `history/${user.uid}/responses`, postId);
            try {
                await updateDoc(postRef, {
                    postResponse: !currentStatus,
                });
                console.log(`Post ${postId} marked as ${!currentStatus ? 'posted' : 'unposted'}.`);
                fetchPostsForReview();
            } catch (error) {
                console.error("Error updating post status:", error);
            }
        }
    };

    useEffect(() => {
        checkAdminStatus();
        fetchTopWidgetSetting();
    }, [checkAdminStatus, fetchTopWidgetSetting]);

    useEffect(() => {
        if (isAdmin) {
            fetchPostsForReview();
        }
    }, [isAdmin, fetchPostsForReview]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isAdmin) {
        return <div>You are not authorized to view this page.</div>;
    }

    return (
        <div className="adminDashboard">
            <h1>Admin Dashboard</h1>
            <div className="toggle-switch">
                Top Widget: 
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={topWidget}
                        onChange={handleToggleTopWidget}
                    />
                    <span className="slider round"></span>
                </label>
                <span className="toggle-label">{topWidget ? "On" : "Off"}</span>
            </div>
            {posts.length === 0 ? (
                <p>No posts to review.</p>
            ) : (
                posts.map(post => (
                    <div key={post.id} className="postCardAdmins">
                        <div>
                            <h2>Author: {post.email}</h2>
                            <p><strong>Site URL:</strong> {post.siteUrl}</p>
                            <p><strong>Post Date:</strong> {post.dateResponse?.toDate().toLocaleString()}</p>
                            <p><strong>About the Post:</strong> {post.aboutPost}</p>
                            <p><strong>Features:</strong> {post.featuresPost}</p>
                            <p><strong>Payment Model:</strong> {post.paymentModel}</p>
                            <p><strong>Post Status:</strong> {post.postResponse ? 'Posted' : 'Not Posted'}</p>
                            {post.postResponse ? (
                                <button onClick={() => togglePostStatus(post.id, post.postResponse)} className="unpostButton">
                                    Unpost
                                </button>
                            ) : (
                                <button onClick={() => togglePostStatus(post.id, post.postResponse)} className="postButton">
                                    Post
                                </button>
                            )}
                        </div>
                        <div>
                            <img src={post.imageUrl} alt='ScoreMe.io - score your product or design.' />
                        </div>
                    </div>
                ))
            )}
        </div>
    );
}

export default AdminDashboard;
