import React, { useEffect, useState, useCallback } from 'react';
import { db, auth } from './firebase';
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import './HallOfFame.css';
import { FaCrown } from 'react-icons/fa'; // Crown icon for first place

function HallOfFame() {
  const [responses, setResponses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchResponses = useCallback(async () => {
    setIsLoading(true);
    let allResponses = [];

    try {
      const usersRef = collection(db, "users");
      const snapshot = await getDocs(usersRef);
      const userIds = snapshot.docs.map(doc => doc.id);

      for (let userId of userIds) {
        let q = query(collection(db, `history/${userId}/responses`), where("postResponse", "==", true));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          continue;
        }

        // Fetch responses and add userName and avatarUrl
        for (const docSnapshot of querySnapshot.docs) {
          const responseData = docSnapshot.data();
          const userDoc = await getDoc(doc(db, "users", userId)); // Fetch the user document based on userId
          
          const userName = userDoc.exists() ? userDoc.data().userName : 'Unknown User'; // Get userName or fallback to 'Unknown User'
          const avatarImage = userDoc.exists() ? userDoc.data().avatarImage : null; // Get avatarImage if available in Firestore

          // Get the user's auth photo URL (Google, Twitter) if avatarImage is not in Firestore
          const authUser = auth.currentUser;
          const authAvatarUrl = authUser && (authUser.photoURL || avatarImage);

          const response = {
            id: docSnapshot.id,
            ownerId: userId,
            userName, // Add userName to response data
            avatarUrl: avatarImage || authAvatarUrl || '/path/to/default/avatar.png', // Use Firestore image, auth image, or default
            ...responseData,
          };
          allResponses.push(response);
        }
      }

      

      // Sort responses by likes in descending order
      allResponses.sort((a, b) => b.likeResponse - a.likeResponse);

      setResponses(allResponses);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching responses:", error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchResponses();
  }, [fetchResponses]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Top 3 responses for the podium
  const podium = responses.slice(0, 3);
  // Remaining responses for list
  const rest = responses.slice(3, 100);

  return (
    <div className="hallOfFame">
      <h1>November Products</h1>

      {/* Podium Display */}
      <div className="podium">
        {/* Silver - 2nd place */}
        {podium[1] && (
          <div className="podium-item second-place">
            <img src={podium[1].avatarUrl} alt="Podium 2" className="podium-image" />
            <div className="username">{podium[1].userName}</div>
            <div className="likes">{podium[1].siteUrl}</div>
            <div className="likes">{podium[1].likeResponse}</div>
          </div>
        )}
        
        {/* Gold - 1st place */}
        {podium[0] && (
          <div className="podium-item first-place">
            <FaCrown className="crown-icon" />
            <img src={podium[0].avatarUrl} alt="Podium 1" className="podium-image" />
            <div className="username">{podium[0].userName}</div>
            <div className="likes">{podium[0].siteUrl}</div>
            <div className="likes">{podium[0].likeResponse}</div>
          </div>
        )}

        {/* Bronze - 3rd place */}
        {podium[2] && (
          <div className="podium-item third-place">
            <img src={podium[2].avatarUrl} alt="Podium 3" className="podium-image" />
            <div className="username">{podium[2].userName}</div>
            <div className="likes">{podium[2].siteUrl}</div>
            <div className="likes">{podium[2].likeResponse}</div>
          </div>
        )}
      </div>

      {/* Top 4-100 List */}
      <div className="product-list">
        {rest.map((product, index) => (
          <div key={product.id} className="product-item">
            <span className="rank">{index + 4}</span>
            <img src={product.avatarUrl} alt={`Rank ${index + 4}`} className="list-avatar" />
            <span className="username">{product.userName}</span>
            <span className="username">{product.siteUrl}</span>
            <span className="likes">Score: {product.likeResponse}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HallOfFame;
