import React, { useEffect, useState, useCallback } from 'react';
import { db, auth } from './firebase';
import { collection, query, where, getDocs, orderBy, doc, updateDoc, increment, getDoc, limit } from 'firebase/firestore';
import './Dashboard.css';
import { FaHeart, FaSortAlphaUp, FaSortAlphaDown, FaCrown } from 'react-icons/fa';  // Import FontAwesome beer icon
import Lottie from 'react-lottie';
import animationData from './images/lottieAnim.json'; // Adjust the path as necessary
import CircularScore from './CircularScore'; // Import the CircularScore component

function Feed() {
    const [responses, setResponses] = useState([]);
    const [topPicks, setTopPicks] = useState([]); // State for top 3 products
    const [sortMode, setSortMode] = useState('dateDesc');
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Sort Options');
    const [isLoading, setIsLoading] = useState(false);
    const [topWidget, setTopWidget] = useState(false); // State to control the visibility of the Top Picks widget
    const [currentMonth, setCurrentMonth] = useState(''); // State to hold the current month name

     // Function to get the current month name
     const getCurrentMonthName = () => {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const date = new Date();
        return monthNames[date.getMonth()];
    };

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (value, label) => {
        handleSortChange(value);
        setSelectedOption(label);
        setIsOpen(false);
    };

    const getUserPhotoURL = async (userId) => {
        const user = auth.currentUser;
        if (user && user.uid === userId) {
            // Check if the authenticated user has a Google or Twitter profile photo
            const googlePhoto = user.providerData.find(provider => provider.providerId === 'google.com')?.photoURL;
            const twitterPhoto = user.providerData.find(provider => provider.providerId === 'twitter.com')?.photoURL;

            if (googlePhoto) return googlePhoto;
            if (twitterPhoto) return twitterPhoto;
        }

        // If no photo from Google/Twitter, check Firestore
        try {
            const userDoc = await getDoc(doc(db, 'users', userId));
            if (userDoc.exists()) {
                return userDoc.data().photoURL || ''; // Return Firestore photoURL or empty if not found
            }
        } catch (error) {
            console.error("Error fetching Firestore photoURL:", error);
        }
        return ''; // Return empty if no photoURL is found
    };

    const fetchTopPicks = useCallback(async () => {
        try {
            const usersRef = collection(db, "users");
            const userSnapshots = await getDocs(usersRef);
            let allResponses = [];

            // Loop through each user and get their responses
            for (const userDoc of userSnapshots.docs) {
                const userId = userDoc.id;
                const responsesRef = collection(db, `history/${userId}/responses`);
                const responseSnapshots = await getDocs(query(responsesRef, orderBy('likeResponse', 'desc')));

                // Add user photo URL to each response
                for (const doc of responseSnapshots.docs) {
                    const responseData = { id: doc.id, ownerId: userId, ...doc.data() };
                    responseData.userPhotoURL = await getUserPhotoURL(userId); // Fetch and add user photo URL
                    allResponses.push(responseData);
                }
            }

            // Sort all responses by likeResponse in descending order and get top 3
            const sortedResponses = allResponses.sort((a, b) => b.likeResponse - a.likeResponse).slice(0, 3);
            setTopPicks(sortedResponses);
        } catch (error) {
            console.error("Error fetching top products:", error);
        }
    }, []);

    const fetchTopWidgetSetting = useCallback(async () => {
        try {
            const controlPanelRef = doc(db, 'controlPanel', '8SGFUGkF19XbA9LgkVfj'); // Replace 'document_id' with your actual document ID
            const docSnap = await getDoc(controlPanelRef);
            if (docSnap.exists()) {
                setTopWidget(docSnap.data().topWidget || false);
            } else {
                console.log("No such document in controlPanel!");
            }
        } catch (error) {
            console.error("Error fetching topWidget setting:", error);
        }
    }, []);

    useEffect(() => {
        fetchTopWidgetSetting();
        fetchTopPicks();
    }, [fetchTopWidgetSetting, fetchTopPicks]);

    const addLikeToResponse = async (responseId, ownerId) => {
        const user = auth.currentUser;
        if (user) {
            const responseRef = doc(db, `history/${ownerId}/responses`, responseId);
            try {
                const docSnap = await getDoc(responseRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    let userLikes = data.userLikes || [];
                    let newLikeResponse = data.likeResponse || 0;

                    if (userLikes.includes(user.uid)) {
                        userLikes = userLikes.filter(uid => uid !== user.uid);
                        newLikeResponse--;
                    } else {
                        userLikes.push(user.uid);
                        newLikeResponse++;
                    }

                    await updateDoc(responseRef, {
                        userLikes: userLikes,
                        likeResponse: newLikeResponse
                    });

                    // Update the local response state to reflect the like change
                    setResponses(prevResponses => prevResponses.map(response =>
                        response.id === responseId
                            ? { ...response, likeResponse: newLikeResponse, isLiked: !response.isLiked }
                            : response
                    ));
                }
            } catch (error) {
                console.error("Error updating like status: ", error);
            }
        }
    };

    const fetchResponses = useCallback(async () => {
        setIsLoading(true);
        let allResponses = [];

        try {
            const usersRef = collection(db, "users");
            const snapshot = await getDocs(usersRef);
            const userIds = snapshot.docs.map(doc => doc.id);

            for (let userId of userIds) {
                let q = query(collection(db, `history/${userId}/responses`), where("postResponse", "==", true));

                const querySnapshot = await getDocs(q);
                if (querySnapshot.empty) {
                    continue;
                }
                querySnapshot.forEach((doc) => {
                    const response = { id: doc.id, ownerId: userId, ...doc.data() };
                    response.isLiked = response.userLikes && response.userLikes.includes(auth.currentUser?.uid); // Check if current user liked this response
                    allResponses.push(response);
                });
            }

            // Apply sorting based on the current sortMode
            switch (sortMode) {
                case 'popularityDesc':
                    allResponses.sort((a, b) => b.likeResponse - a.likeResponse);
                    break;
                case 'popularityAsc':
                    allResponses.sort((a, b) => a.likeResponse - b.likeResponse);
                    break;
                case 'dateDesc':
                    allResponses.sort((a, b) => b.dateResponse.toDate() - a.dateResponse.toDate());
                    break;
                case 'dateAsc':
                    allResponses.sort((a, b) => a.dateResponse.toDate() - b.dateResponse.toDate());
                    break;
                case 'alphabetAsc':
                    allResponses.sort((a, b) => a.siteUrl.localeCompare(b.siteUrl));
                    break;
                case 'alphabetDesc':
                    allResponses.sort((a, b) => b.siteUrl.localeCompare(a.siteUrl));
                    break;
                default:
                    allResponses.sort((a, b) => b.dateResponse.toDate() - a.dateResponse.toDate());
            }

            setResponses(allResponses);
            setIsLoading(false); // End loading
            console.log("All Responses:", allResponses);
        } catch (error) {
            console.error("Error fetching responses:", error);
            setIsLoading(false);
        }
    }, [sortMode]);

    useEffect(() => {
        fetchResponses();
        setCurrentMonth(getCurrentMonthName());
    }, [fetchResponses]);

    const handleSortChange = (newSortMode) => {
        setSortMode(newSortMode);
    };

    const processTextResponse = (textResponse) => {
        const sections = {};
        const responseLines = textResponse.split('\n');
        let currentSectionTitle = null;
        let currentText = [];
        let currentScore = null;

        responseLines.forEach(line => {
            const scoreMatch = line.match(/Score: (\d+(?:\/\d+)?)/); // Matches '80', '80/100'
            if (scoreMatch) {
                currentScore = scoreMatch[1]; // Capture just the numeric score
                // Skip adding this line to the currentText
                return; // This prevents the score line from being added to the text body
            }
            const foundTitle = criteriaList.find(title => line.startsWith(title)) || (line.startsWith("Overall Feedback") ? "Overall Feedback" : null);
            if (foundTitle) {
                if (currentSectionTitle !== null) {
                    sections[currentSectionTitle] = {
                        text: currentText.join('\n'),
                        score: currentScore
                    };
                }
                currentSectionTitle = foundTitle;
                currentText = [];
                currentScore = null; // Reset score for the next section
            } else {
                currentText.push(line);
            }
        });

        if (currentSectionTitle !== null) {
            sections[currentSectionTitle] = {
                text: currentText.join('\n'),
                score: currentScore
            };
        }

        return sections;
    };

    // List of all criteria
    const criteriaList = [
        "Design Foundations",
        "Visual Hierarchy",
        "Layout and Spacing",
        "Color",
        "Typography",
        "Theory",
        "Depth",
        "Design Assets",
        "Details"
    ];

    const togglePost = async (id, currentPostStatus) => {
        const user = auth.currentUser;
        if (user) {
            const responseRef = doc(db, `history/${user.uid}/responses`, id);
            try {
                await updateDoc(responseRef, {
                    postResponse: !currentPostStatus
                });
                console.log(`Post status for ${id} updated to: ${!currentPostStatus}`);

                // After updating, fetch the responses again to refresh the UI
                fetchResponses();
            } catch (error) {
                console.error("Error updating document: ", error);
            }
        }
    };

    return (
        <div className='dashboard'>
            {isLoading ? (
                <div className="loader">
                    <Lottie options={{ loop: true, autoplay: true, animationData: animationData, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }} height={400} width={400} />
                </div>
            ) : (
                <>
                    {topWidget && ( // Conditionally render the Top Picks widget
                <div className="top-picks-widget">
                    <h3>Top {currentMonth}</h3>
                    <div className="top-picks-list">
                        {topPicks.map((product, index) => (
                            <div key={product.id} className={`top-pick-item position-${index + 1}`}>
                                {index === 0 && <FaCrown className="crown-icon" />}
                                <img src={product.userPhotoURL} alt="User" className="user-photo" />
                                <div className="top-pick-info">
                                    <div className="username">
                                        {new URL(product.siteUrl).hostname.replace('www.', '')}
                                    </div>
                                    <div className="likes-count">{product.likeResponse || 0}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
                    <div className='dropOption'>
                        <div className="selectedOptionDrop" onClick={toggleDropdown}>{selectedOption}</div>
                        {isOpen && (
                            <div className='dropdownSorting'>
                                <div className="selectedOptionDropItem" onClick={() => handleOptionClick('dateDesc', 'Date')}>Date <FaSortAlphaDown /></div>
                                <div className="selectedOptionDropItem" onClick={() => handleOptionClick('dateAsc', 'Date')}>Date <FaSortAlphaUp /></div>
                                <div className="selectedOptionDropItem" onClick={() => handleOptionClick('popularityDesc', 'Most Popular')}>Most Popular <FaSortAlphaDown /></div>
                                <div className="selectedOptionDropItem" onClick={() => handleOptionClick('popularityAsc', 'Most Popular')}>Most Popular <FaSortAlphaUp /></div>
                                <div className="selectedOptionDropItem" onClick={() => handleOptionClick('alphabetDesc', 'Alphabet')}>Alphabet <FaSortAlphaDown /></div>
                                <div className="selectedOptionDropItem" onClick={() => handleOptionClick('alphabetAsc', 'Alphabet')}>Alphabet <FaSortAlphaUp /></div>
                            </div>
                        )}
                    </div>
                    {responses.map((response) => (
                        <div key={response.id} className="responseCard">
                            <div className='titleInformationBox'>
                                <div className="date">{response.email}</div>
                                <div className="date"><a className="responseSiteUrl" target='blank' href={response.siteUrl}>{response.siteUrl}</a></div>
                                <div className="date">{response.dateResponse?.toDate().toLocaleString()}</div>
                            </div>
                            <div className='responseCardSections'>
                                <div className='responseBoxBoard'>
                                    <div className='responseImageBox'>
                                        <img src={response.imageUrl} alt="Response Screenshot" className='responseImage' />
                                    </div>
                                </div>
                                <div className='responseBoxBoardRightFeed'>
                                    <div className='overallScoreBox'><h2>Overall Score: </h2><span className='scoreStyle'> <CircularScore score={response.overallScore} /></span></div>
                                    <div><b>About product:</b></div>
                                    <div>{response.aboutPost}</div>
                                    <div><b>Features:</b></div>
                                    <div>{response.featuresPost}</div>
                                    <div><b>Payment model:</b></div>
                                    <div>{response.paymentModel}</div>
                                </div>
                            </div>
                            {response.postResponse && (
                                <div className="likes" onClick={() => addLikeToResponse(response.id, response.ownerId)}>
                                    {response.likeResponse || 0}
                                    <span className={`likeButton ${response.isLiked ? 'active' : ''}`}>
                                        <FaHeart />
                                    </span>
                                </div>
                            )}
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

export default Feed;
